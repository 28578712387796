import React, { Component } from 'react';
import styled from 'styled-components';
import { Home, CalendarEvent, Users, Stack, Search } from 'tabler-icons-react';

const Container = styled.button`
    height: 50px;
    width: 100%;
    border: 0;
    border-left: ${(props) => (props.active ? '3px solid #1DD3B0' : '0')};
    outline: 0;
    padding: 0;
    // border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${(props) => (props.pointer ? 'pointer' : 'auto')};
    box-sizing: border-box;
    :active {
        transform: scale(0.9);
    }
    transition: transform 0.2s ease, background-color 0.2s ease;
    pointer-events: ${(props) => (props.frozen ? 'none' : 'auto')};
    background-color: #2a2c35;
`;

const SVG = styled.img`
    width: 35px;
    height: 35px;
    user-select: none;
    user-drag: none;
`;

const Image = styled.img`
    width: 35px;
    height: 35px;
    user-select: none;
    user-drag: none;
    border-radius: 50%;
    object-fit: cover;
    transition: border 0.2s ease;
`;

class MenuItem extends Component {
    render() {
        switch (this.props.icon) {
            case 'home':
                this.icon = <Home size={35} color={this.props.active ? '#1DD3B0' : '#ededfd'} />;
                break;
            case 'calendar':
                this.icon = <CalendarEvent size={35} color={this.props.active ? '#1DD3B0' : '#ededfd'} />;
                break;
            case 'users':
                this.icon = <Users size={35} color={this.props.active ? '#1DD3B0' : '#ededfd'} />;
                break;
            case 'stack':
                this.icon = <Stack size={35} color={this.props.active ? '#1DD3B0' : '#ededfd'} />;
                break;
            case 'search':
                this.icon = <Search size={35} color={this.props.active ? '#1DD3B0' : '#ededfd'} />;
                break;
            default:
                if (this.props.icon === 'logo') {
                    this.icon = (
                        <SVG src={require(`../icons/logo-white.svg`)} />
                    );
                } else {
                    this.icon = <Image src={this.props.iconSource} />;
                }
        }

        return (
            <Container
                style={this.props.style}
                active={this.props.active}
                pointer={this.props.pointer}
                onClick={this.props.onClick}
                frozen={this.props.frozen}>
                {this.icon}
            </Container>
        );
    }
}

export default MenuItem;
