import React, { Component } from 'react';
import styled from 'styled-components';

import PatientsSidebar from './PatientsSidebar';
import PatientView from './PatientView';
// import Page from '../page/Page';

const Container = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
`;

const PatientsSidebarUnder = styled.div`
    height: 100%;
    min-width: 225px;
    background-color: #f9f9f9;
`;

// const PageContainer = styled.div`
//     min-height: 100vh;
//     width: auto;
//     display: flex;
//     justify-content: center;
//     background-color: #f9f9f9;
// `;

class Patients extends Component {
    render() {
        return (
            <Container>
                <PatientsSidebar />
                <PatientsSidebarUnder />
                {/* <PageContainer>
                    <Page />
                </PageContainer> */}
                <PatientView />
            </Container>
        );
    }
}

export default Patients;
