import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import styled from 'styled-components';

import Sidebar from './core/Menu';
import Home from './home/Home';
import Schedule from './schedule/Schedule';
import Page from './page/Page';
import Patients from './patients/Patients';

const Root = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
`;

const SidebarUnder = styled.div`
    height: 100vh;
    min-width: 85px;
`;

const Body = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

function App() {
    return (
        <Root>
            <BrowserRouter>
                <Sidebar />
                <SidebarUnder />
                <Body>
                    <Switch>
                        <Route exact path='/' component={Home} />
                        <Route exact path='/schedule' component={Schedule} />
                        <Route exact path='/patients' component={Patients} />
                        <Route exact path='/wiki' component={Page} />
                        <Route exact path='/search' component={Page} />
                        <Route exact path='/profile' component={Page} />
                    </Switch>
                </Body>
            </BrowserRouter>
        </Root>
    );
}

export default App;
