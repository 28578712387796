import React, { Component } from 'react';
import styled from 'styled-components';

import ScheduleTitle from './ScheduleTitle';

const Container = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9f9f9;
`;

class Schedule extends Component {
    render() {
        return (
            <Container>
                <ScheduleTitle />
            </Container>
        );
    }
}

export default Schedule;
