import React, { Component } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    height: 125px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #eff0f1;
    padding: 25px 35px;
`;

const Row = styled.div`
    display: flex;
    align-items: center;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

const Photo = styled.img`
    height: 75px;
    width: 75px;
    border-radius: 6px;
    object-fit: cover;
`;

const T1 = styled.h1``;

const T3 = styled.h3`
    opacity: 0.75;
    font-weight: 600;
`;

class PatientViewTitle extends Component {
    render() {
        return (
            <Container>
                <Row>
                    <Photo
                        src={
                            'https://www.raymondhechen.com/images/DSC_0414-low.jpg'
                        }
                    />
                    <div style={{ width: '25px' }} />
                    <Column>
                        <T3>PATIENT</T3>
                        <T1>Raymond Chen</T1>
                    </Column>
                </Row>
                <Row></Row>
            </Container>
        );
    }
}

export default PatientViewTitle;
