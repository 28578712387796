import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import ContentEditable from 'react-contenteditable';
import './RowEditable.css';
import DotsIcon from '../icons/dots-vertical-double.svg';
import PlusIcon from '../icons/plus.svg';

const Container = styled.div`
    display: flex;
`;

const Button = styled.img`
    width: 20px;
    height: 20px;
    padding: 5px;
    border-radius: 6px;
    margin-left: 2.5px;
    cursor: pointer;
    opacity: 0;
    transition: background-color 0.2s ease, opacity 0.2s ease;
    :hover {
        opacity: 1;
        background-color: #ededfd;
    }
    visibility: hidden;
`;

const DropdownContainer = styled.div`
    z-index: 99;
    position: absolute;
    right: 0;
    width: 225px;
    height: 300px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 5px 0;
    transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
`;

const DropdownValue = styled.div`
    width: calc(100% - 25px);
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 25px;
    transition: background-color 0.2s ease;
    :hover {
        background-color: #ededfd;
    }
`;

class RowEditable extends Component {
    constructor() {
        super();
        this.state = {
            showButtons: false,
            dropShowing: false,
        };
        this.contentEditable = React.createRef();
        this.dropdownRef = React.createRef();
        this.showDrop = this.showDrop.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        if (this.props.autofocus) {
            ReactDOM.findDOMNode(this.contentEditable.current).focus();
        }
    }

    componentDidUpdate() {
        if (this.props.autofocus) {
            ReactDOM.findDOMNode(this.contentEditable.current).focus();
        }
    }

    showHideButtons() {
        if (this.state.showButtons) {
            document.getElementById(
                'button-a' + this.props.index
            ).style.visibility = 'hidden';
            document.getElementById(
                'button-a' + this.props.index
            ).style.opacity = 0;
            document.getElementById(
                'button-b' + this.props.index
            ).style.visibility = 'hidden';
            document.getElementById(
                'button-b' + this.props.index
            ).style.opacity = 0;
        } else {
            document.getElementById(
                'button-a' + this.props.index
            ).style.visibility = 'visible';
            document.getElementById(
                'button-a' + this.props.index
            ).style.opacity = 0.75;
            document.getElementById(
                'button-b' + this.props.index
            ).style.visibility = 'visible';
            document.getElementById(
                'button-b' + this.props.index
            ).style.opacity = 0.75;
        }

        this.setState({
            showButtons: !this.state.showButtons,
        });
    }

    showDrop(e) {
        if (!e) e = window.event;
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();

        if (this.state.dropShowing) {
            document.removeEventListener('mousedown', this.handleClickOutside);
            this.setState({
                dropShowing: false,
            });
        } else {
            document.addEventListener('mousedown', this.handleClickOutside);
            this.setState({
                dropShowing: true,
            });
        }
    }

    handleClickOutside(event) {
        if (
            this.dropdownRef &&
            this.dropdownRef.current &&
            !this.dropdownRef.current.contains(event.target)
        ) {
            this.showDrop();
        }
    }

    render() {
        return (
            <Container
                onMouseEnter={() => this.showHideButtons()}
                onMouseLeave={() => this.showHideButtons()}>
                <ContentEditable
                    className='row-editable'
                    innerRef={this.contentEditable}
                    html={this.props.html} // innerHTML of the editable div
                    disabled={this.props.disabled} // use true to disable editing
                    onChange={this.props.onChange} // handle innerHTML change
                />
                <div style={{ position: 'relative' }} ref={this.dropdownRef}>
                    <Button
                        id={'button-a' + this.props.index}
                        src={DotsIcon}
                        onClick={(e) => this.showDrop(e)}
                        style={{ width: '15px' }}
                    />
                    <CSSTransition
                        in={this.state.dropShowing}
                        timeout={{enter: 0, exit: 100}}
                        unmountOnExit
                        classNames='dropdown-transition'>
                        <DropdownContainer id={'dropdown' + this.props.index}>
                            <DropdownValue>Title</DropdownValue>
                            <DropdownValue>Text</DropdownValue>
                            <DropdownValue>Bulleted List</DropdownValue>
                            <DropdownValue>Numbered List</DropdownValue>
                            <DropdownValue>Image</DropdownValue>
                            <DropdownValue>Chart</DropdownValue>
                            <DropdownValue>Callout</DropdownValue>
                            <DropdownValue>Equation</DropdownValue>
                        </DropdownContainer>
                    </CSSTransition>
                </div>
                <Button
                    id={'button-b' + this.props.index}
                    src={PlusIcon}
                    onClick={this.props.addCallback}
                />
            </Container>
        );
    }
}

export default RowEditable;
