import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import MenuButton from './MenuItem';
// import MenuSelect from './MenuSelect';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 85px;
    box-sizing: border-box;
    // border-right: 1px solid #dadfe7;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #2a2c35;
    z-index: 100;
`;

const Divider = styled.div`
    height: ${(props) => props.height};
`;

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeButton: 0,
            userStatus: 0,
        };
    }

    handleActiveChange(i) {
        this.setState({
            activeButton: i,
        });

        switch (i) {
            case 0:
                this.props.history.push('/');
                break;
            case 1:
                this.props.history.push('/schedule');
                break;
            case 2:
                this.props.history.push('/patients');
                break;
            default:
                this.props.history.push('');
                break;
        }
    }

    handleStatusChange(value) {
        this.setState({
            userStatus: value,
        });
    }

    render() {
        return (
            <Container>
                <Divider height='25px' />
                <MenuButton frozen icon='logo' />
                <Divider height='35px' />
                <MenuButton
                    pointer
                    icon='home'
                    active={this.state.activeButton === 0}
                    onClick={() => this.handleActiveChange(0)}
                />
                <Divider height='20px' />
                <MenuButton
                    pointer
                    icon='calendar'
                    active={this.state.activeButton === 1}
                    onClick={() => this.handleActiveChange(1)}
                />
                <Divider height='20px' />
                <MenuButton
                    pointer
                    icon='users'
                    active={this.state.activeButton === 2}
                    onClick={() => this.handleActiveChange(2)}
                />
                <Divider height='20px' />
                <MenuButton
                    pointer
                    icon='stack'
                    active={this.state.activeButton === 3}
                    onClick={() => this.handleActiveChange(3)}
                />
                <Divider height='20px' />
                <MenuButton
                    pointer
                    icon='search'
                    active={this.state.activeButton === 4}
                    onClick={() => this.handleActiveChange(4)}
                />
                {/* <MenuSelect status={this.state.userStatus} style={{ marginTop: 'auto' }} callback={(value) => this.handleStatusChange(value)} /> */}
                {/* <Divider height='20px'/> */}
                <MenuButton
                    pointer
                    style={{ marginTop: 'auto' }}
                    icon='profile'
                    iconSource={'https://www.raymondhechen.com/images/DSC_0414-low.jpg'}
                    active={this.state.activeButton === 5}
                    onClick={() => this.handleActiveChange(5)}
                />
                <Divider height='25px' />
            </Container>
        );
    }
}

export default withRouter(Menu);
