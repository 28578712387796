import React, { Component } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    position: fixed;
    height: 100vh;
    width: 175px;
    border-right: 1px solid #EFF0F1;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    z-index: 90;
    padding: 25px 25px;
    // box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
`;

const T1 = styled.h1``;

const T2 = styled.h2``;

class PatientsSidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mounted: false,
        };
    }

    componentDidMount() {
        this.setState({
            mounted: true,
        });
    }

    render() {
        return (
            <Container>
                <T1>Patients</T1>
                <T2>All Patients</T2>
                <T2>Upcoming</T2>
                <T2>Find Patient</T2>
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                condimentum tempor maximus. Etiam maximus nulla nec sagittis
                mollis. Morbi dapibus neque tortor, in placerat tortor luctus
                id. Nulla non elit tortor. Aliquam euismod nunc eget tristique
                convallis. Nullam nec sapien mattis, egestas elit ac, ultricies
                justo. Donec venenatis elit nec nulla porttitor, nec placerat
                mauris pharetra. Suspendisse elementum eu metus at finibus.
                Etiam rhoncus leo erat, ut porttitor sapien accumsan quis.
                Pellentesque pharetra velit non molestie bibendum. Pellentesque
                efficitur tincidunt mauris, ac iaculis nunc. In consectetur
                bibendum dui sit amet pretium. */}
            </Container>
        );
    }
}

export default PatientsSidebar;
