import React, { Component } from 'react';
import styled from 'styled-components';

import PageEditable from './PageEditable';
import RowEditable from './RowEditable';
import DoctorIcon from '../icons/doctor.svg';
import LocationIcon from '../icons/pin.svg';
import CalendarIcon from '../icons/calendar-dates.svg';
import DownloadIcon from '../icons/download.svg';

const Container = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding: 40px 50px 50px 50px;
`;

const Header = styled.div`
    width: 100%;
    margin-bottom: 25px;
`;

const Body = styled.div`
    width: 100%;
    height: 100%;
`;

const Row = styled.div`
    display: flex;
    align-items: center;
`;

const TypeBadge = styled.div`
    height: 45px;
    padding: 0 20px;
    background-color: #673edc;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: default;
`;

const Icon = styled.img`
    height: 20px;
    width: 20px;
    cursor: ${props => props.pointer ? 'pointer' : 'default'};
`;

class Page extends Component {
    constructor() {
        super();
        const monthNames = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];

        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let yyyy = today.getFullYear();
        this.state = {
            title: '<h1>X-Ray: Untitled</h1>',
            practioner: '<h3>John Doe M.D.</h3>',
            location: '<h3>Duke Health</h3>',
            date: `<h3>${
                monthNames[today.getMonth()] + ' ' + dd + ', ' + yyyy
            }</h3>`,
            content: [
                '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed condimentum tempor maximus. Etiam maximus nulla nec sagittis mollis. Morbi dapibus neque tortor, in placerat tortor luctus id. Nulla non elit tortor. Aliquam euismod nunc eget tristique convallis. Nullam nec sapien mattis, egestas elit ac, ultricies justo. Donec venenatis elit nec nulla porttitor, nec placerat mauris pharetra. Suspendisse elementum eu metus at finibus. Etiam rhoncus leo erat, ut porttitor sapien accumsan quis. Pellentesque pharetra velit non molestie bibendum. Pellentesque efficitur tincidunt mauris, ac iaculis nunc. In consectetur bibendum dui sit amet pretium.</p>',
                '<p>Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse aliquam est magna, nec tristique massa convallis maximus. Praesent vel elementum justo. Donec molestie nisl nunc, a tincidunt purus scelerisque vel. Nunc volutpat in massa quis volutpat. Curabitur fermentum volutpat nisl. Nam elementum turpis id venenatis porta. Vivamus elit ligula, facilisis in tempor quis, luctus eu orci. Morbi efficitur hendrerit augue sed sodales.</p>',
                '<p>Duis ullamcorper tortor nec neque convallis, et tempus neque placerat. Suspendisse et odio ullamcorper, tempus mi a, volutpat augue. Donec pulvinar nulla nec dolor volutpat convallis. Mauris lacus ipsum, venenatis quis fringilla vitae, ullamcorper ut ligula. Suspendisse potenti. Suspendisse facilisis vel lectus quis hendrerit. Nulla efficitur congue justo, ut scelerisque elit. Vivamus sollicitudin nibh eu ex auctor suscipit. Duis molestie libero gravida consectetur pulvinar. Donec rutrum pellentesque nisl, vitae hendrerit erat placerat et. Morbi quis tortor id odio aliquam dignissim ut in risus.</p>',
            ],
            newRowIndex: 0,
            newRowIndicator: false
        };
    }

    handleTitleChange(event, target) {
        this.setState({
            [target]: event.target.value,
        });
    }

    handleContentChange(event, target) {
        const updated = this.state.content.slice();
        updated[target] = event.target.value;

        this.setState({
            content: updated,
            newRowIndicator: false
        });
    }

    handleAddRow(target) {
        let updated = this.state.content.slice();
        updated.splice(target + 1, 0, '');
        this.setState({
            content: updated,
            newRowIndex: target + 1,
            newRowIndicator: true
        });
    }

    render() {
        this.content = this.state.content.map((item, index) => {
            return (
                <RowEditable
                    key={index}
                    index={index}
                    html={item} // innerHTML of the editable div
                    disabled={false} // use true to disable editing
                    onChange={(e) => this.handleContentChange(e, index)} // handle innerHTML change
                    addCallback={() => this.handleAddRow(index)}
                    autofocus={this.state.newRowIndex === index && this.state.newRowIndicator ? true : false}
                />
            );
        });

        return (
            <Container>
                <Header>
                    <Row>
                        <TypeBadge>IMAGING</TypeBadge>
                        <div style={{ width: '25px' }} />
                        <PageEditable
                            html={this.state.title} // innerHTML of the editable div
                            disabled={false} // use true to disable editing
                            onChange={(e) => this.handleTitleChange(e, 'title')} // handle innerHTML change
                        />
                    </Row>
                    <Row style={{ height: '15px' }} />
                    <Row>
                        <Icon src={DoctorIcon} />
                        <div style={{ width: '5px' }} />
                        <PageEditable
                            html={this.state.practioner} // innerHTML of the editable div
                            disabled={false} // use true to disable editing
                            onChange={(e) =>
                                this.handleTitleChange(e, 'practioner')
                            } // handle innerHTML change
                        />
                        <div style={{ width: '25px' }} />
                        <Icon src={LocationIcon} />
                        <div style={{ width: '5px' }} />
                        <PageEditable
                            html={this.state.location} // innerHTML of the editable div
                            disabled={false} // use true to disable editing
                            onChange={(e) =>
                                this.handleTitleChange(e, 'location')
                            } // handle innerHTML change
                        />
                        <div style={{ width: '25px' }} />
                        <Icon src={CalendarIcon} />
                        <div style={{ width: '5px' }} />
                        <PageEditable
                            html={this.state.date} // innerHTML of the editable div
                            disabled={true} // use true to disable editing
                            onChange={(e) => this.handleTitleChange(e, 'date')} // handle innerHTML change
                        />
                        <Icon src={DownloadIcon} style={{marginLeft: 'auto'}} pointer/>
                    </Row>
                    <Row
                        style={{
                            borderBottom: '2px solid #DADFE7',
                            height: '15px',
                        }}
                    />
                </Header>
                <Body>{this.content}</Body>
            </Container>
        );
    }
}

export default Page;
