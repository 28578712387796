import React, { Component } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    height: 75px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #EFF0F1;
    background-color: #ffffff;
`;

const Title = styled.h1`
    margin-left: 25px;
`;

class ScheduleTitle extends Component {
    render() {
        return (
            <Container>
                <Title>
                    Schedule
                </Title>
            </Container>
        );
    }
}

export default ScheduleTitle;