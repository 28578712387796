import React, { Component } from 'react';
import styled from 'styled-components';

import PatientViewTitle from './PatientViewTitle';

const Container = styled.div`
    width: 100%;
    display: flex;
    background-color: #f9f9f9;
`;

class PatientView extends Component {
    render() {
        return (
            <Container>
                <PatientViewTitle />
            </Container>
        );
    }
}

export default PatientView;
