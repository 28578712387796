import React, { Component } from 'react';
import styled from 'styled-components';
import ContentEditable from 'react-contenteditable';
import './PageEditable.css';

const Container = styled.div``;

class PageEditable extends Component {
    constructor() {
        super();
        this.contentEditable = React.createRef();
        this.state = {
            html: '<h1>Hello World</h1>',
        };
    }

    handleChange = (evt) => {
        this.setState({ html: evt.target.value });
    };

    render() {
        return (
            <Container className='editable'>
                <ContentEditable
                    className='editable'
                    innerRef={this.contentEditable}
                    html={this.props.html} // innerHTML of the editable div
                    disabled={this.props.disabled} // use true to disable editing
                    onChange={this.props.onChange} // handle innerHTML change
                />
            </Container>
        );
    }
}

export default PageEditable;
